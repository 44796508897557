<template>
  <span
    v-show="hasRank"
    class="subtitle is-rank"
    v-html="rank"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    rank: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    hasRank() {
      return Object.keys(this.$route.query).includes(this.currentDirectory.primary_filter.field);
    },
  },
};
</script>
